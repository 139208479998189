/* tslint:disable */
/* eslint-disable */
/**
 * SCKAN Composer API
 * SCKAN Composer API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnatomicalEntity
 */
export interface AnatomicalEntity {
    /**
     * 
     * @type {number}
     * @memberof AnatomicalEntity
     */
    'id': number;
    /**
     * 
     * @type {AnatomicalEntityMeta}
     * @memberof AnatomicalEntity
     */
    'simple_entity': AnatomicalEntityMeta;
    /**
     * 
     * @type {AnatomicalEntityIntersection}
     * @memberof AnatomicalEntity
     */
    'region_layer': AnatomicalEntityIntersection;
    /**
     * 
     * @type {string}
     * @memberof AnatomicalEntity
     */
    'synonyms': string;
}
/**
 * 
 * @export
 * @interface AnatomicalEntityIntersection
 */
export interface AnatomicalEntityIntersection {
    /**
     * 
     * @type {number}
     * @memberof AnatomicalEntityIntersection
     */
    'id': number;
    /**
     * 
     * @type {Layer}
     * @memberof AnatomicalEntityIntersection
     */
    'layer': Layer;
    /**
     * 
     * @type {Region}
     * @memberof AnatomicalEntityIntersection
     */
    'region': Region;
}
/**
 * 
 * @export
 * @interface AnatomicalEntityMeta
 */
export interface AnatomicalEntityMeta {
    /**
     * 
     * @type {number}
     * @memberof AnatomicalEntityMeta
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AnatomicalEntityMeta
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnatomicalEntityMeta
     */
    'ontology_uri': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AvailableTransitions24dEnum = {
    Draft: 'draft',
    ComposeNow: 'compose_now',
    InProgress: 'in_progress',
    ToBeReviewed: 'to_be_reviewed',
    Revise: 'revise',
    Rejected: 'rejected',
    NpoApproved: 'npo_approved',
    Exported: 'exported',
    Invalid: 'invalid'
} as const;

export type AvailableTransitions24dEnum = typeof AvailableTransitions24dEnum[keyof typeof AvailableTransitions24dEnum];


/**
 * Adds nested create feature
 * @export
 * @interface BaseConnectivityStatement
 */
export interface BaseConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof BaseConnectivityStatement
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseConnectivityStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof BaseConnectivityStatement
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {User}
     * @memberof BaseConnectivityStatement
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof BaseConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseConnectivityStatement
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof BaseConnectivityStatement
     */
    'modified_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseConnectivityStatement
     */
    'has_notes': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BlankEnum = {
    Empty: ''
} as const;

export type BlankEnum = typeof BlankEnum[keyof typeof BlankEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const CircuitTypeEnum = {
    Sensory: 'SENSORY',
    Motor: 'MOTOR',
    Intrinsic: 'INTRINSIC',
    Projection: 'PROJECTION',
    Anaxonic: 'ANAXONIC'
} as const;

export type CircuitTypeEnum = typeof CircuitTypeEnum[keyof typeof CircuitTypeEnum];


/**
 * Connectivity Statement
 * @export
 * @interface ConnectivityStatement
 */
export interface ConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof ConnectivityStatement
     */
    'sentence': Sentence;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ConnectivityStatement
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof ConnectivityStatement
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof ConnectivityStatement
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'state': string;
    /**
     * 
     * @type {Array<AvailableTransitions24dEnum>}
     * @memberof ConnectivityStatement
     */
    'available_transitions': Array<AvailableTransitions24dEnum>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof ConnectivityStatement
     */
    'origins'?: Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof ConnectivityStatement
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof ConnectivityStatement
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof ConnectivityStatement
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof ConnectivityStatement
     */
    'projection_phenotype': ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'journey': string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof ConnectivityStatement
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof ConnectivityStatement
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof ConnectivityStatement
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof ConnectivityStatement
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof ConnectivityStatement
     */
    'sex': Sex;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectivityStatement
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'modified_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectivityStatement
     */
    'has_notes': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'statement_preview': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ConnectivityStatement
     */
    'errors': Array<any>;
}
/**
 * @type ConnectivityStatementCircuitType
 * @export
 */
export type ConnectivityStatementCircuitType = BlankEnum | CircuitTypeEnum;

/**
 * @type ConnectivityStatementLaterality
 * @export
 */
export type ConnectivityStatementLaterality = BlankEnum | LateralityEnum;

/**
 * @type ConnectivityStatementProjection
 * @export
 */
export type ConnectivityStatementProjection = BlankEnum | ProjectionEnum;

/**
 * Connectivity Statement
 * @export
 * @interface ConnectivityStatementUpdate
 */
export interface ConnectivityStatementUpdate {
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof ConnectivityStatementUpdate
     */
    'sentence': Sentence;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ConnectivityStatementUpdate
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof ConnectivityStatementUpdate
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof ConnectivityStatementUpdate
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'state'?: string;
    /**
     * 
     * @type {Array<AvailableTransitions24dEnum>}
     * @memberof ConnectivityStatementUpdate
     */
    'available_transitions': Array<AvailableTransitions24dEnum>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectivityStatementUpdate
     */
    'origins': Array<number>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof ConnectivityStatementUpdate
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof ConnectivityStatementUpdate
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof ConnectivityStatementUpdate
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof ConnectivityStatementUpdate
     */
    'projection_phenotype': ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'journey': string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof ConnectivityStatementUpdate
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof ConnectivityStatementUpdate
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof ConnectivityStatementUpdate
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof ConnectivityStatementUpdate
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof ConnectivityStatementUpdate
     */
    'sex': Sex;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectivityStatementUpdate
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'modified_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectivityStatementUpdate
     */
    'has_notes': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'statement_preview': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ConnectivityStatementUpdate
     */
    'errors': Array<any>;
}
/**
 * Destination
 * @export
 * @interface Destination
 */
export interface Destination {
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'connectivity_statement': number;
    /**
     * 
     * @type {TypeC11Enum}
     * @memberof Destination
     */
    'type'?: TypeC11Enum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Destination
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Destination
     */
    'from_entities'?: Array<number>;
}


/**
 * Destination with Custom Logic for from_entities
 * @export
 * @interface DestinationSerializerDetails
 */
export interface DestinationSerializerDetails {
    /**
     * 
     * @type {number}
     * @memberof DestinationSerializerDetails
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DestinationSerializerDetails
     */
    'connectivity_statement_id': number;
    /**
     * 
     * @type {TypeC11Enum}
     * @memberof DestinationSerializerDetails
     */
    'type'?: TypeC11Enum;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof DestinationSerializerDetails
     */
    'anatomical_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof DestinationSerializerDetails
     */
    'from_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {string}
     * @memberof DestinationSerializerDetails
     */
    'are_connections_explicit': string;
}


/**
 * Knowledge Statement
 * @export
 * @interface KnowledgeStatement
 */
export interface KnowledgeStatement {
    /**
     * 
     * @type {number}
     * @memberof KnowledgeStatement
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeStatement
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof KnowledgeStatement
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof KnowledgeStatement
     */
    'origins'?: Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof KnowledgeStatement
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof KnowledgeStatement
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof KnowledgeStatement
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'reference_uri'?: string | null;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof KnowledgeStatement
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'journey': string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof KnowledgeStatement
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof KnowledgeStatement
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof KnowledgeStatement
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Sex}
     * @memberof KnowledgeStatement
     */
    'sex': Sex;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'statement_preview': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LateralityEnum = {
    Right: 'RIGHT',
    Left: 'LEFT'
} as const;

export type LateralityEnum = typeof LateralityEnum[keyof typeof LateralityEnum];


/**
 * 
 * @export
 * @interface Layer
 */
export interface Layer {
    /**
     * 
     * @type {number}
     * @memberof Layer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'ontology_uri': string;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {number}
     * @memberof Login
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface Logout
 */
export interface Logout {
    /**
     * 
     * @type {number}
     * @memberof Logout
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof Logout
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Logout
     */
    'redirect_url'?: string;
}
/**
 * Note
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'user': string | null;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    'connectivity_statement_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    'sentence_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NullEnum = {
    Null: 'null'
} as const;

export type NullEnum = typeof NullEnum[keyof typeof NullEnum];


/**
 * 
 * @export
 * @interface PaginatedAnatomicalEntityList
 */
export interface PaginatedAnatomicalEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAnatomicalEntityList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAnatomicalEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAnatomicalEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof PaginatedAnatomicalEntityList
     */
    'results'?: Array<AnatomicalEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedBaseConnectivityStatementList
 */
export interface PaginatedBaseConnectivityStatementList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<BaseConnectivityStatement>}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'results'?: Array<BaseConnectivityStatement>;
}
/**
 * 
 * @export
 * @interface PaginatedDestinationList
 */
export interface PaginatedDestinationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedDestinationList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDestinationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDestinationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Destination>}
     * @memberof PaginatedDestinationList
     */
    'results'?: Array<Destination>;
}
/**
 * 
 * @export
 * @interface PaginatedKnowledgeStatementList
 */
export interface PaginatedKnowledgeStatementList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedKnowledgeStatementList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedKnowledgeStatementList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedKnowledgeStatementList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<KnowledgeStatement>}
     * @memberof PaginatedKnowledgeStatementList
     */
    'results'?: Array<KnowledgeStatement>;
}
/**
 * 
 * @export
 * @interface PaginatedNoteList
 */
export interface PaginatedNoteList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNoteList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNoteList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNoteList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Note>}
     * @memberof PaginatedNoteList
     */
    'results'?: Array<Note>;
}
/**
 * 
 * @export
 * @interface PaginatedPhenotypeList
 */
export interface PaginatedPhenotypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPhenotypeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPhenotypeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPhenotypeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Phenotype>}
     * @memberof PaginatedPhenotypeList
     */
    'results'?: Array<Phenotype>;
}
/**
 * 
 * @export
 * @interface PaginatedProjectionPhenotypeList
 */
export interface PaginatedProjectionPhenotypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ProjectionPhenotype>}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'results'?: Array<ProjectionPhenotype>;
}
/**
 * 
 * @export
 * @interface PaginatedSentenceList
 */
export interface PaginatedSentenceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSentenceList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSentenceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSentenceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Sentence>}
     * @memberof PaginatedSentenceList
     */
    'results'?: Array<Sentence>;
}
/**
 * 
 * @export
 * @interface PaginatedSexList
 */
export interface PaginatedSexList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSexList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSexList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSexList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Sex>}
     * @memberof PaginatedSexList
     */
    'results'?: Array<Sex>;
}
/**
 * 
 * @export
 * @interface PaginatedSpecieList
 */
export interface PaginatedSpecieList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSpecieList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSpecieList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSpecieList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof PaginatedSpecieList
     */
    'results'?: Array<Specie>;
}
/**
 * 
 * @export
 * @interface PaginatedTagList
 */
export interface PaginatedTagList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedTagList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTagList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTagList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PaginatedTagList
     */
    'results'?: Array<Tag>;
}
/**
 * 
 * @export
 * @interface PaginatedViaList
 */
export interface PaginatedViaList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedViaList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedViaList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedViaList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Via>}
     * @memberof PaginatedViaList
     */
    'results'?: Array<Via>;
}
/**
 * Connectivity Statement
 * @export
 * @interface PatchedConnectivityStatementUpdate
 */
export interface PatchedConnectivityStatementUpdate {
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sentence'?: Sentence;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'owner'?: User;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'state'?: string;
    /**
     * 
     * @type {Array<AvailableTransitions24dEnum>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'available_transitions'?: Array<AvailableTransitions24dEnum>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'origins'?: Array<number>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'phenotype'?: Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'projection_phenotype'?: ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'journey'?: string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sex'?: Sex;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'modified_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'has_notes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'statement_preview'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'errors'?: Array<any>;
}
/**
 * Destination
 * @export
 * @interface PatchedDestination
 */
export interface PatchedDestination {
    /**
     * 
     * @type {number}
     * @memberof PatchedDestination
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedDestination
     */
    'connectivity_statement'?: number;
    /**
     * 
     * @type {TypeC11Enum}
     * @memberof PatchedDestination
     */
    'type'?: TypeC11Enum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedDestination
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedDestination
     */
    'from_entities'?: Array<number>;
}


/**
 * Note
 * @export
 * @interface PatchedNote
 */
export interface PatchedNote {
    /**
     * 
     * @type {string}
     * @memberof PatchedNote
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNote
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNote
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedNote
     */
    'connectivity_statement_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedNote
     */
    'sentence_id'?: number;
}
/**
 * Sentence
 * @export
 * @interface PatchedSentence
 */
export interface PatchedSentence {
    /**
     * 
     * @type {number}
     * @memberof PatchedSentence
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedSentence
     */
    'pmid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'pmcid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'doi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'batch_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'external_ref'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PatchedSentence
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {User}
     * @memberof PatchedSentence
     */
    'owner'?: User;
    /**
     * 
     * @type {number}
     * @memberof PatchedSentence
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'modified_date'?: string;
    /**
     * 
     * @type {Array<SentenceAvailableTransitionsEnum>}
     * @memberof PatchedSentence
     */
    'available_transitions'?: Array<SentenceAvailableTransitionsEnum>;
    /**
     * 
     * @type {Array<SentenceConnectivityStatement>}
     * @memberof PatchedSentence
     */
    'connectivity_statements'?: Array<SentenceConnectivityStatement>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedSentence
     */
    'has_notes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'pmid_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'pmcid_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'doi_uri'?: string;
}
/**
 * Via
 * @export
 * @interface PatchedVia
 */
export interface PatchedVia {
    /**
     * 
     * @type {number}
     * @memberof PatchedVia
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedVia
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedVia
     */
    'connectivity_statement'?: number;
    /**
     * 
     * @type {TypeB60Enum}
     * @memberof PatchedVia
     */
    'type'?: TypeB60Enum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedVia
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedVia
     */
    'from_entities'?: Array<number>;
}


/**
 * Phenotype
 * @export
 * @interface Phenotype
 */
export interface Phenotype {
    /**
     * 
     * @type {number}
     * @memberof Phenotype
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Phenotype
     */
    'name': string;
}
/**
 * Profile
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof Profile
     */
    'user': User;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'is_triage_operator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'is_curator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'is_reviewer'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectionEnum = {
    Ipsi: 'IPSI',
    Contrat: 'CONTRAT',
    Bi: 'BI'
} as const;

export type ProjectionEnum = typeof ProjectionEnum[keyof typeof ProjectionEnum];


/**
 * Phenotype
 * @export
 * @interface ProjectionPhenotype
 */
export interface ProjectionPhenotype {
    /**
     * 
     * @type {number}
     * @memberof ProjectionPhenotype
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectionPhenotype
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectionPhenotype
     */
    'ontology_uri': string;
}
/**
 * Provenance
 * @export
 * @interface Provenance
 */
export interface Provenance {
    /**
     * 
     * @type {number}
     * @memberof Provenance
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Provenance
     */
    'uri': string;
    /**
     * 
     * @type {number}
     * @memberof Provenance
     */
    'connectivity_statement_id': number;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'ontology_uri': string;
    /**
     * 
     * @type {Array<Layer>}
     * @memberof Region
     */
    'layers': Array<Layer>;
}
/**
 * Sentence
 * @export
 * @interface Sentence
 */
export interface Sentence {
    /**
     * 
     * @type {number}
     * @memberof Sentence
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof Sentence
     */
    'pmid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'pmcid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'doi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'batch_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'external_ref'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Sentence
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {User}
     * @memberof Sentence
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof Sentence
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'modified_date': string;
    /**
     * 
     * @type {Array<SentenceAvailableTransitionsEnum>}
     * @memberof Sentence
     */
    'available_transitions': Array<SentenceAvailableTransitionsEnum>;
    /**
     * 
     * @type {Array<SentenceConnectivityStatement>}
     * @memberof Sentence
     */
    'connectivity_statements': Array<SentenceConnectivityStatement>;
    /**
     * 
     * @type {boolean}
     * @memberof Sentence
     */
    'has_notes': boolean;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'pmid_uri': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'pmcid_uri': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'doi_uri': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SentenceAvailableTransitionsEnum = {
    Open: 'open',
    NeedsFurtherReview: 'needs_further_review',
    ComposeLater: 'compose_later',
    ReadyToCompose: 'ready_to_compose',
    ComposeNow: 'compose_now',
    Completed: 'completed',
    Excluded: 'excluded'
} as const;

export type SentenceAvailableTransitionsEnum = typeof SentenceAvailableTransitionsEnum[keyof typeof SentenceAvailableTransitionsEnum];


/**
 * Connectivity Statement
 * @export
 * @interface SentenceConnectivityStatement
 */
export interface SentenceConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'sentence_id': number;
    /**
     * 
     * @type {string}
     * @memberof SentenceConnectivityStatement
     */
    'knowledge_statement': string;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof SentenceConnectivityStatement
     */
    'provenances': Array<Provenance>;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof SentenceConnectivityStatement
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof SentenceConnectivityStatement
     */
    'projection_phenotype': ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {LateralityEnum}
     * @memberof SentenceConnectivityStatement
     */
    'laterality': LateralityEnum | null;
    /**
     * 
     * @type {ProjectionEnum}
     * @memberof SentenceConnectivityStatement
     */
    'projection': ProjectionEnum | null;
    /**
     * 
     * @type {CircuitTypeEnum}
     * @memberof SentenceConnectivityStatement
     */
    'circuit_type': CircuitTypeEnum | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof SentenceConnectivityStatement
     */
    'species': Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof SentenceConnectivityStatement
     */
    'sex': Sex;
    /**
     * 
     * @type {string}
     * @memberof SentenceConnectivityStatement
     */
    'apinatomy_model': string | null;
    /**
     * 
     * @type {string}
     * @memberof SentenceConnectivityStatement
     */
    'additional_information': string | null;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {User}
     * @memberof SentenceConnectivityStatement
     */
    'owner': User;
}


/**
 * Sex
 * @export
 * @interface Sex
 */
export interface Sex {
    /**
     * 
     * @type {number}
     * @memberof Sex
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sex
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Sex
     */
    'ontology_uri': string;
}
/**
 * Specie
 * @export
 * @interface Specie
 */
export interface Specie {
    /**
     * 
     * @type {number}
     * @memberof Specie
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Specie
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Specie
     */
    'ontology_uri'?: string | null;
}
/**
 * Note Tag
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'tag': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TypeB60Enum = {
    Axon: 'AXON',
    Dendrite: 'DENDRITE'
} as const;

export type TypeB60Enum = typeof TypeB60Enum[keyof typeof TypeB60Enum];


/**
 * 
 * @export
 * @enum {string}
 */

export const TypeC11Enum = {
    AxonT: 'AXON-T',
    AfferentT: 'AFFERENT-T',
    Unknown: 'UNKNOWN'
} as const;

export type TypeC11Enum = typeof TypeC11Enum[keyof typeof TypeC11Enum];


/**
 * User
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
}
/**
 * Via
 * @export
 * @interface Via
 */
export interface Via {
    /**
     * 
     * @type {number}
     * @memberof Via
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Via
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof Via
     */
    'connectivity_statement'?: number;
    /**
     * 
     * @type {TypeB60Enum}
     * @memberof Via
     */
    'type'?: TypeB60Enum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Via
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Via
     */
    'from_entities'?: Array<number>;
}


/**
 * Via Serializer with Custom Logic for from_entities
 * @export
 * @interface ViaSerializerDetails
 */
export interface ViaSerializerDetails {
    /**
     * 
     * @type {number}
     * @memberof ViaSerializerDetails
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ViaSerializerDetails
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof ViaSerializerDetails
     */
    'connectivity_statement_id': number;
    /**
     * 
     * @type {TypeB60Enum}
     * @memberof ViaSerializerDetails
     */
    'type'?: TypeB60Enum;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof ViaSerializerDetails
     */
    'anatomical_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof ViaSerializerDetails
     */
    'from_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {string}
     * @memberof ViaSerializerDetails
     */
    'are_connections_explicit': string;
}



/**
 * ComposerApi - axios parameter creator
 * @export
 */
export const ComposerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * AnatomicalEntity
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityList: async (excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/anatomical-entity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (excludeIds) {
                localVarQueryParameter['exclude_ids'] = excludeIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AnatomicalEntity
         * @param {number} id A unique integer value identifying this Anatomical Entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerAnatomicalEntityRetrieve', 'id', id)
            const localVarPath = `/api/composer/anatomical-entity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddProvenanceCreate: async (id: number, uri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAddProvenanceCreate', 'id', id)
            // verify required parameter 'uri' is not null or undefined
            assertParamExists('composerConnectivityStatementAddProvenanceCreate', 'uri', uri)
            const localVarPath = `/api/composer/connectivity-statement/{id}/add_provenance/{uri}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"uri"}}`, encodeURIComponent(String(uri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddSpecieCreate: async (id: number, specieId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAddSpecieCreate', 'id', id)
            // verify required parameter 'specieId' is not null or undefined
            assertParamExists('composerConnectivityStatementAddSpecieCreate', 'specieId', specieId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/add_specie/{specie_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"specie_id"}}`, encodeURIComponent(String(specieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddTagCreate: async (id: number, tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAddTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerConnectivityStatementAddTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/add_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCloneStatementRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementCloneStatementRetrieve', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/clone_statement/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCreate: async (connectivityStatement?: ConnectivityStatement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/connectivity-statement/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectivityStatement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} provenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelProvenanceDestroy: async (id: number, provenanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDelProvenanceDestroy', 'id', id)
            // verify required parameter 'provenanceId' is not null or undefined
            assertParamExists('composerConnectivityStatementDelProvenanceDestroy', 'provenanceId', provenanceId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/del_provenance/{provenance_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"provenance_id"}}`, encodeURIComponent(String(provenanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelSpecieCreate: async (id: number, specieId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDelSpecieCreate', 'id', id)
            // verify required parameter 'specieId' is not null or undefined
            assertParamExists('composerConnectivityStatementDelSpecieCreate', 'specieId', specieId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/del_specie/{specie_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"specie_id"}}`, encodeURIComponent(String(specieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelTagCreate: async (id: number, tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDelTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerConnectivityStatementDelTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/del_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDestroy', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} transition 
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDoTransitionCreate: async (id: number, transition: string, connectivityStatement?: ConnectivityStatement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDoTransitionCreate', 'id', id)
            // verify required parameter 'transition' is not null or undefined
            assertParamExists('composerConnectivityStatementDoTransitionCreate', 'transition', transition)
            const localVarPath = `/api/composer/connectivity-statement/{id}/do_transition/{transition}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"transition"}}`, encodeURIComponent(String(transition)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectivityStatement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {string} [knowledgeStatement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementList: async (destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/connectivity-statement/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (excludeIds) {
                localVarQueryParameter['exclude_ids'] = excludeIds.join(COLLECTION_FORMATS.csv);
            }

            if (excludeSentenceId !== undefined) {
                localVarQueryParameter['exclude_sentence_id'] = excludeSentenceId;
            }

            if (knowledgeStatement !== undefined) {
                localVarQueryParameter['knowledge_statement'] = knowledgeStatement;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (sentenceId !== undefined) {
                localVarQueryParameter['sentence_id'] = sentenceId;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementPartialUpdate: async (id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedConnectivityStatementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementRetrieve', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementUpdate: async (id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementUpdate', 'id', id)
            // verify required parameter 'connectivityStatementUpdate' is not null or undefined
            assertParamExists('composerConnectivityStatementUpdate', 'connectivityStatementUpdate', connectivityStatementUpdate)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectivityStatementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationCreate: async (destination: Destination, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'destination' is not null or undefined
            assertParamExists('composerDestinationCreate', 'destination', destination)
            const localVarPath = `/api/composer/destination/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(destination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationDestroy', 'id', id)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationList: async (connectivityStatementId?: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/destination/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (connectivityStatementId !== undefined) {
                localVarQueryParameter['connectivity_statement_id'] = connectivityStatementId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {PatchedDestination} [patchedDestination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationPartialUpdate: async (id: number, patchedDestination?: PatchedDestination, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedDestination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationRetrieve', 'id', id)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationUpdate: async (id: number, destination: Destination, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationUpdate', 'id', id)
            // verify required parameter 'destination' is not null or undefined
            assertParamExists('composerDestinationUpdate', 'destination', destination)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(destination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerJsonschemasRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/jsonschemas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteCreate: async (note: Note, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'note' is not null or undefined
            assertParamExists('composerNoteCreate', 'note', note)
            const localVarPath = `/api/composer/note/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(note, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteDestroy', 'id', id)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number | null} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number | null} [sentenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteList: async (connectivityStatementId?: number | null, limit?: number, offset?: number, sentenceId?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/note/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (connectivityStatementId !== undefined) {
                localVarQueryParameter['connectivity_statement_id'] = connectivityStatementId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sentenceId !== undefined) {
                localVarQueryParameter['sentence_id'] = sentenceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {PatchedNote} [patchedNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNotePartialUpdate: async (id: number, patchedNote?: PatchedNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNotePartialUpdate', 'id', id)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteRetrieve', 'id', id)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/note-tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteTagRetrieve', 'id', id)
            const localVarPath = `/api/composer/note-tag/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteUpdate: async (id: number, note: Note, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteUpdate', 'id', id)
            // verify required parameter 'note' is not null or undefined
            assertParamExists('composerNoteUpdate', 'note', note)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(note, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/phenotype/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Phenotype
         * @param {number} id A unique integer value identifying this phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerPhenotypeRetrieve', 'id', id)
            const localVarPath = `/api/composer/phenotype/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProfileMyRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/profile/my/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Projection Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/projection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Projection Phenotype
         * @param {number} id A unique integer value identifying this projection phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerProjectionRetrieve', 'id', id)
            const localVarPath = `/api/composer/projection/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAddTagCreate: async (id: number, tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceAddTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerSentenceAddTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/sentence/{id}/add_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceCreate: async (sentence: Sentence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sentence' is not null or undefined
            assertParamExists('composerSentenceCreate', 'sentence', sentence)
            const localVarPath = `/api/composer/sentence/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDelTagCreate: async (id: number, tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceDelTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerSentenceDelTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/sentence/{id}/del_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {string} transition 
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDoTransitionCreate: async (id: number, transition: string, sentence: Sentence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceDoTransitionCreate', 'id', id)
            // verify required parameter 'transition' is not null or undefined
            assertParamExists('composerSentenceDoTransitionCreate', 'transition', transition)
            // verify required parameter 'sentence' is not null or undefined
            assertParamExists('composerSentenceDoTransitionCreate', 'sentence', sentence)
            const localVarPath = `/api/composer/sentence/{id}/do_transition/{transition}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"transition"}}`, encodeURIComponent(String(transition)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceList: async (exclude?: Array<string>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sentence/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (exclude) {
                localVarQueryParameter['exclude'] = exclude.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentencePartialUpdate: async (id: number, patchedSentence?: PatchedSentence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentencePartialUpdate', 'id', id)
            const localVarPath = `/api/composer/sentence/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceRetrieve', 'id', id)
            const localVarPath = `/api/composer/sentence/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceUpdate: async (id: number, sentence: Sentence, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceUpdate', 'id', id)
            // verify required parameter 'sentence' is not null or undefined
            assertParamExists('composerSentenceUpdate', 'sentence', sentence)
            const localVarPath = `/api/composer/sentence/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sex
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sex/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sex
         * @param {number} id A unique integer value identifying this sex.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSexRetrieve', 'id', id)
            const localVarPath = `/api/composer/sex/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specie
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieList: async (limit?: number, name?: string, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/specie/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specie
         * @param {number} id A unique integer value identifying this specie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSpecieRetrieve', 'id', id)
            const localVarPath = `/api/composer/specie/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerTagRetrieve', 'id', id)
            const localVarPath = `/api/composer/tag/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaCreate: async (via: Via, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'via' is not null or undefined
            assertParamExists('composerViaCreate', 'via', via)
            const localVarPath = `/api/composer/via/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(via, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaDestroy', 'id', id)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaList: async (connectivityStatementId?: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/via/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (connectivityStatementId !== undefined) {
                localVarQueryParameter['connectivity_statement_id'] = connectivityStatementId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {PatchedVia} [patchedVia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaPartialUpdate: async (id: number, patchedVia?: PatchedVia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedVia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaRetrieve', 'id', id)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaUpdate: async (id: number, via: Via, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaUpdate', 'id', id)
            // verify required parameter 'via' is not null or undefined
            assertParamExists('composerViaUpdate', 'via', via)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(via, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComposerApi - functional programming interface
 * @export
 */
export const ComposerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComposerApiAxiosParamCreator(configuration)
    return {
        /**
         * AnatomicalEntity
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerAnatomicalEntityList(excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAnatomicalEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerAnatomicalEntityList(excludeIds, limit, name, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * AnatomicalEntity
         * @param {number} id A unique integer value identifying this Anatomical Entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerAnatomicalEntityRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnatomicalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerAnatomicalEntityRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAddProvenanceCreate(id: number, uri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAddProvenanceCreate(id, uri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAddSpecieCreate(id: number, specieId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAddSpecieCreate(id, specieId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAddTagCreate(id: number, tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAddTagCreate(id, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementCloneStatementRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementCloneStatementRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementCreate(connectivityStatement?: ConnectivityStatement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementCreate(connectivityStatement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} provenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDelProvenanceDestroy(id: number, provenanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDelProvenanceDestroy(id, provenanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDelSpecieCreate(id: number, specieId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDelSpecieCreate(id, specieId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDelTagCreate(id: number, tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDelTagCreate(id, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} transition 
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDoTransitionCreate(id: number, transition: string, connectivityStatement?: ConnectivityStatement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDoTransitionCreate(id, transition, connectivityStatement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {string} [knowledgeStatement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementList(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBaseConnectivityStatementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementList(destinations, excludeIds, excludeSentenceId, knowledgeStatement, limit, notes, offset, ordering, origins, sentenceId, state, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementPartialUpdate(id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementPartialUpdate(id, patchedConnectivityStatementUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementUpdate(id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementUpdate(id, connectivityStatementUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destination
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationCreate(destination: Destination, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationCreate(destination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destination
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationList(connectivityStatementId?: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDestinationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationList(connectivityStatementId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {PatchedDestination} [patchedDestination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationPartialUpdate(id: number, patchedDestination?: PatchedDestination, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationPartialUpdate(id, patchedDestination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationUpdate(id: number, destination: Destination, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationUpdate(id, destination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerJsonschemasRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerJsonschemasRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Note
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteCreate(note: Note, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteCreate(note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Note
         * @param {number | null} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number | null} [sentenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteList(connectivityStatementId?: number | null, limit?: number, offset?: number, sentenceId?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNoteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteList(connectivityStatementId, limit, offset, sentenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {PatchedNote} [patchedNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNotePartialUpdate(id: number, patchedNote?: PatchedNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNotePartialUpdate(id, patchedNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteTagList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteTagList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteTagRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteTagRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteUpdate(id: number, note: Note, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteUpdate(id, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerPhenotypeList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPhenotypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerPhenotypeList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Phenotype
         * @param {number} id A unique integer value identifying this phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerPhenotypeRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Phenotype>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerPhenotypeRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerProfileMyRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerProfileMyRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Projection Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerProjectionList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectionPhenotypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerProjectionList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Projection Phenotype
         * @param {number} id A unique integer value identifying this projection phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerProjectionRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectionPhenotype>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerProjectionRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceAddTagCreate(id: number, tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceAddTagCreate(id, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceCreate(sentence: Sentence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceCreate(sentence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceDelTagCreate(id: number, tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceDelTagCreate(id, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {string} transition 
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceDoTransitionCreate(id: number, transition: string, sentence: Sentence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceDoTransitionCreate(id, transition, sentence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceList(exclude?: Array<string>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSentenceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceList(exclude, limit, notes, offset, ordering, state, tags, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentencePartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentencePartialUpdate(id, patchedSentence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceUpdate(id: number, sentence: Sentence, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceUpdate(id, sentence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sex
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSexList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSexList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSexList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sex
         * @param {number} id A unique integer value identifying this sex.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSexRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSexRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Specie
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSpecieList(limit?: number, name?: string, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSpecieList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSpecieList(limit, name, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Specie
         * @param {number} id A unique integer value identifying this specie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSpecieRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Specie>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSpecieRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerTagList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerTagList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerTagRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerTagRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Via
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaCreate(via: Via, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaCreate(via, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Via
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaList(connectivityStatementId?: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedViaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaList(connectivityStatementId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {PatchedVia} [patchedVia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaPartialUpdate(id: number, patchedVia?: PatchedVia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaPartialUpdate(id, patchedVia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaUpdate(id: number, via: Via, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaUpdate(id, via, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComposerApi - factory interface
 * @export
 */
export const ComposerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComposerApiFp(configuration)
    return {
        /**
         * AnatomicalEntity
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityList(excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options?: any): AxiosPromise<PaginatedAnatomicalEntityList> {
            return localVarFp.composerAnatomicalEntityList(excludeIds, limit, name, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * AnatomicalEntity
         * @param {number} id A unique integer value identifying this Anatomical Entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityRetrieve(id: number, options?: any): AxiosPromise<AnatomicalEntity> {
            return localVarFp.composerAnatomicalEntityRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddProvenanceCreate(id: number, uri: string, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAddProvenanceCreate(id, uri, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddSpecieCreate(id: number, specieId: number, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAddSpecieCreate(id, specieId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddTagCreate(id: number, tagId: number, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAddTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCloneStatementRetrieve(id: number, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementCloneStatementRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCreate(connectivityStatement?: ConnectivityStatement, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementCreate(connectivityStatement, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} provenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelProvenanceDestroy(id: number, provenanceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.composerConnectivityStatementDelProvenanceDestroy(id, provenanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelSpecieCreate(id: number, specieId: number, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementDelSpecieCreate(id, specieId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelTagCreate(id: number, tagId: number, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementDelTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.composerConnectivityStatementDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} transition 
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDoTransitionCreate(id: number, transition: string, connectivityStatement?: ConnectivityStatement, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementDoTransitionCreate(id, transition, connectivityStatement, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {string} [knowledgeStatement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementList(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options?: any): AxiosPromise<PaginatedBaseConnectivityStatementList> {
            return localVarFp.composerConnectivityStatementList(destinations, excludeIds, excludeSentenceId, knowledgeStatement, limit, notes, offset, ordering, origins, sentenceId, state, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementPartialUpdate(id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementPartialUpdate(id, patchedConnectivityStatementUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementRetrieve(id: number, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementUpdate(id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options?: any): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementUpdate(id, connectivityStatementUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationCreate(destination: Destination, options?: any): AxiosPromise<Destination> {
            return localVarFp.composerDestinationCreate(destination, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.composerDestinationDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationList(connectivityStatementId?: number, limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedDestinationList> {
            return localVarFp.composerDestinationList(connectivityStatementId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {PatchedDestination} [patchedDestination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationPartialUpdate(id: number, patchedDestination?: PatchedDestination, options?: any): AxiosPromise<Destination> {
            return localVarFp.composerDestinationPartialUpdate(id, patchedDestination, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationRetrieve(id: number, options?: any): AxiosPromise<Destination> {
            return localVarFp.composerDestinationRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationUpdate(id: number, destination: Destination, options?: any): AxiosPromise<Destination> {
            return localVarFp.composerDestinationUpdate(id, destination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerJsonschemasRetrieve(options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.composerJsonschemasRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteCreate(note: Note, options?: any): AxiosPromise<Note> {
            return localVarFp.composerNoteCreate(note, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.composerNoteDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number | null} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number | null} [sentenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteList(connectivityStatementId?: number | null, limit?: number, offset?: number, sentenceId?: number | null, options?: any): AxiosPromise<PaginatedNoteList> {
            return localVarFp.composerNoteList(connectivityStatementId, limit, offset, sentenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {PatchedNote} [patchedNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNotePartialUpdate(id: number, patchedNote?: PatchedNote, options?: any): AxiosPromise<Note> {
            return localVarFp.composerNotePartialUpdate(id, patchedNote, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteRetrieve(id: number, options?: any): AxiosPromise<Note> {
            return localVarFp.composerNoteRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagList(limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedTagList> {
            return localVarFp.composerNoteTagList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagRetrieve(id: number, options?: any): AxiosPromise<Tag> {
            return localVarFp.composerNoteTagRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteUpdate(id: number, note: Note, options?: any): AxiosPromise<Note> {
            return localVarFp.composerNoteUpdate(id, note, options).then((request) => request(axios, basePath));
        },
        /**
         * Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeList(limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedPhenotypeList> {
            return localVarFp.composerPhenotypeList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Phenotype
         * @param {number} id A unique integer value identifying this phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeRetrieve(id: number, options?: any): AxiosPromise<Phenotype> {
            return localVarFp.composerPhenotypeRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProfileMyRetrieve(options?: any): AxiosPromise<Profile> {
            return localVarFp.composerProfileMyRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Projection Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionList(limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedProjectionPhenotypeList> {
            return localVarFp.composerProjectionList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Projection Phenotype
         * @param {number} id A unique integer value identifying this projection phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionRetrieve(id: number, options?: any): AxiosPromise<ProjectionPhenotype> {
            return localVarFp.composerProjectionRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAddTagCreate(id: number, tagId: number, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceAddTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceCreate(sentence: Sentence, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceCreate(sentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDelTagCreate(id: number, tagId: number, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceDelTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {string} transition 
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDoTransitionCreate(id: number, transition: string, sentence: Sentence, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceDoTransitionCreate(id, transition, sentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceList(exclude?: Array<string>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options?: any): AxiosPromise<PaginatedSentenceList> {
            return localVarFp.composerSentenceList(exclude, limit, notes, offset, ordering, state, tags, title, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentencePartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentencePartialUpdate(id, patchedSentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceRetrieve(id: number, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceUpdate(id: number, sentence: Sentence, options?: any): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceUpdate(id, sentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sex
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexList(limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedSexList> {
            return localVarFp.composerSexList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Sex
         * @param {number} id A unique integer value identifying this sex.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexRetrieve(id: number, options?: any): AxiosPromise<Sex> {
            return localVarFp.composerSexRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Specie
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieList(limit?: number, name?: string, offset?: number, options?: any): AxiosPromise<PaginatedSpecieList> {
            return localVarFp.composerSpecieList(limit, name, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Specie
         * @param {number} id A unique integer value identifying this specie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieRetrieve(id: number, options?: any): AxiosPromise<Specie> {
            return localVarFp.composerSpecieRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagList(limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedTagList> {
            return localVarFp.composerTagList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagRetrieve(id: number, options?: any): AxiosPromise<Tag> {
            return localVarFp.composerTagRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaCreate(via: Via, options?: any): AxiosPromise<Via> {
            return localVarFp.composerViaCreate(via, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.composerViaDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaList(connectivityStatementId?: number, limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedViaList> {
            return localVarFp.composerViaList(connectivityStatementId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {PatchedVia} [patchedVia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaPartialUpdate(id: number, patchedVia?: PatchedVia, options?: any): AxiosPromise<Via> {
            return localVarFp.composerViaPartialUpdate(id, patchedVia, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaRetrieve(id: number, options?: any): AxiosPromise<Via> {
            return localVarFp.composerViaRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaUpdate(id: number, via: Via, options?: any): AxiosPromise<Via> {
            return localVarFp.composerViaUpdate(id, via, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComposerApi - object-oriented interface
 * @export
 * @class ComposerApi
 * @extends {BaseAPI}
 */
export class ComposerApi extends BaseAPI {
    /**
     * AnatomicalEntity
     * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {string} [name] 
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerAnatomicalEntityList(excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerAnatomicalEntityList(excludeIds, limit, name, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AnatomicalEntity
     * @param {number} id A unique integer value identifying this Anatomical Entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerAnatomicalEntityRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerAnatomicalEntityRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {string} uri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAddProvenanceCreate(id: number, uri: string, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAddProvenanceCreate(id, uri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} specieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAddSpecieCreate(id: number, specieId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAddSpecieCreate(id, specieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAddTagCreate(id: number, tagId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAddTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementCloneStatementRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementCloneStatementRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {ConnectivityStatement} [connectivityStatement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementCreate(connectivityStatement?: ConnectivityStatement, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementCreate(connectivityStatement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} provenanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDelProvenanceDestroy(id: number, provenanceId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDelProvenanceDestroy(id, provenanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} specieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDelSpecieCreate(id: number, specieId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDelSpecieCreate(id, specieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDelTagCreate(id: number, tagId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDelTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDestroy(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {string} transition 
     * @param {ConnectivityStatement} [connectivityStatement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDoTransitionCreate(id: number, transition: string, connectivityStatement?: ConnectivityStatement, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDoTransitionCreate(id, transition, connectivityStatement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {Array<number>} [destinations] 
     * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
     * @param {number} [excludeSentenceId] 
     * @param {string} [knowledgeStatement] 
     * @param {number} [limit] Number of results to return per page.
     * @param {boolean} [notes] Checks if entity has notes
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
     * @param {Array<number>} [origins] 
     * @param {number} [sentenceId] 
     * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementList(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementList(destinations, excludeIds, excludeSentenceId, knowledgeStatement, limit, notes, offset, ordering, origins, sentenceId, state, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementPartialUpdate(id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementPartialUpdate(id, patchedConnectivityStatementUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementUpdate(id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementUpdate(id, connectivityStatementUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {Destination} destination 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationCreate(destination: Destination, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationCreate(destination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationDestroy(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} [connectivityStatementId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationList(connectivityStatementId?: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationList(connectivityStatementId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {PatchedDestination} [patchedDestination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationPartialUpdate(id: number, patchedDestination?: PatchedDestination, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationPartialUpdate(id, patchedDestination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {Destination} destination 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationUpdate(id: number, destination: Destination, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationUpdate(id, destination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerJsonschemasRetrieve(options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerJsonschemasRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {Note} note 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteCreate(note: Note, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteCreate(note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteDestroy(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number | null} [connectivityStatementId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {number | null} [sentenceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteList(connectivityStatementId?: number | null, limit?: number, offset?: number, sentenceId?: number | null, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteList(connectivityStatementId, limit, offset, sentenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {PatchedNote} [patchedNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNotePartialUpdate(id: number, patchedNote?: PatchedNote, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNotePartialUpdate(id, patchedNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteTagList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteTagList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} id A unique integer value identifying this tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteTagRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteTagRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {Note} note 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteUpdate(id: number, note: Note, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteUpdate(id, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Phenotype
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerPhenotypeList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerPhenotypeList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Phenotype
     * @param {number} id A unique integer value identifying this phenotype.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerPhenotypeRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerPhenotypeRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerProfileMyRetrieve(options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerProfileMyRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Projection Phenotype
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerProjectionList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerProjectionList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Projection Phenotype
     * @param {number} id A unique integer value identifying this projection phenotype.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerProjectionRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerProjectionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceAddTagCreate(id: number, tagId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceAddTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {Sentence} sentence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceCreate(sentence: Sentence, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceCreate(sentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceDelTagCreate(id: number, tagId: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceDelTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {string} transition 
     * @param {Sentence} sentence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceDoTransitionCreate(id: number, transition: string, sentence: Sentence, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceDoTransitionCreate(id, transition, sentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {Array<string>} [exclude] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {boolean} [notes] Checks if entity has notes
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
     * @param {Array<ComposerSentenceListStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceList(exclude?: Array<string>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceList(exclude, limit, notes, offset, ordering, state, tags, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {PatchedSentence} [patchedSentence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentencePartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentencePartialUpdate(id, patchedSentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {Sentence} sentence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceUpdate(id: number, sentence: Sentence, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceUpdate(id, sentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sex
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSexList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSexList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sex
     * @param {number} id A unique integer value identifying this sex.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSexRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSexRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Specie
     * @param {number} [limit] Number of results to return per page.
     * @param {string} [name] 
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSpecieList(limit?: number, name?: string, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSpecieList(limit, name, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Specie
     * @param {number} id A unique integer value identifying this specie.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSpecieRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSpecieRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerTagList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerTagList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} id A unique integer value identifying this tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerTagRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerTagRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {Via} via 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaCreate(via: Via, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaCreate(via, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaDestroy(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} [connectivityStatementId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaList(connectivityStatementId?: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaList(connectivityStatementId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {PatchedVia} [patchedVia] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaPartialUpdate(id: number, patchedVia?: PatchedVia, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaPartialUpdate(id, patchedVia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaRetrieve(id: number, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {Via} via 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaUpdate(id: number, via: Via, options?: AxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaUpdate(id, via, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ComposerConnectivityStatementListOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Id2: 'id',
    LastEdited2: 'last_edited'
} as const;
export type ComposerConnectivityStatementListOrderingEnum = typeof ComposerConnectivityStatementListOrderingEnum[keyof typeof ComposerConnectivityStatementListOrderingEnum];
/**
 * @export
 */
export const ComposerConnectivityStatementListStateEnum = {
    ComposeNow: 'compose_now',
    Draft: 'draft',
    Exported: 'exported',
    InProgress: 'in_progress',
    Invalid: 'invalid',
    NpoApproved: 'npo_approved',
    Rejected: 'rejected',
    Revise: 'revise',
    ToBeReviewed: 'to_be_reviewed'
} as const;
export type ComposerConnectivityStatementListStateEnum = typeof ComposerConnectivityStatementListStateEnum[keyof typeof ComposerConnectivityStatementListStateEnum];
/**
 * @export
 */
export const ComposerSentenceListOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Id2: 'id',
    LastEdited2: 'last_edited'
} as const;
export type ComposerSentenceListOrderingEnum = typeof ComposerSentenceListOrderingEnum[keyof typeof ComposerSentenceListOrderingEnum];
/**
 * @export
 */
export const ComposerSentenceListStateEnum = {
    Completed: 'completed',
    ComposeLater: 'compose_later',
    ComposeNow: 'compose_now',
    Excluded: 'excluded',
    NeedsFurtherReview: 'needs_further_review',
    Open: 'open',
    ReadyToCompose: 'ready_to_compose'
} as const;
export type ComposerSentenceListStateEnum = typeof ComposerSentenceListStateEnum[keyof typeof ComposerSentenceListStateEnum];


/**
 * MetacellAuthApi - axios parameter creator
 * @export
 */
export const MetacellAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLoginRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/metacell_auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLogoutRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/metacell_auth/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetacellAuthApi - functional programming interface
 * @export
 */
export const MetacellAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetacellAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metacellAuthLoginRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metacellAuthLoginRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metacellAuthLogoutRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Logout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metacellAuthLogoutRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetacellAuthApi - factory interface
 * @export
 */
export const MetacellAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetacellAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLoginRetrieve(options?: any): AxiosPromise<Login> {
            return localVarFp.metacellAuthLoginRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLogoutRetrieve(options?: any): AxiosPromise<Logout> {
            return localVarFp.metacellAuthLogoutRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetacellAuthApi - object-oriented interface
 * @export
 * @class MetacellAuthApi
 * @extends {BaseAPI}
 */
export class MetacellAuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetacellAuthApi
     */
    public metacellAuthLoginRetrieve(options?: AxiosRequestConfig) {
        return MetacellAuthApiFp(this.configuration).metacellAuthLoginRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetacellAuthApi
     */
    public metacellAuthLogoutRetrieve(options?: AxiosRequestConfig) {
        return MetacellAuthApiFp(this.configuration).metacellAuthLogoutRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
         * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [originUris] Multiple values may be separated by commas.
         * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
         * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerKnowledgeStatementList: async (destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/knowledge-statement/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (destinationUris) {
                localVarQueryParameter['destination_uris'] = destinationUris.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (originUris) {
                localVarQueryParameter['origin_uris'] = originUris.join(COLLECTION_FORMATS.csv);
            }

            if (populationUris) {
                localVarQueryParameter['population_uris'] = populationUris.join(COLLECTION_FORMATS.csv);
            }

            if (viaUris) {
                localVarQueryParameter['via_uris'] = viaUris.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
         * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [originUris] Multiple values may be separated by commas.
         * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
         * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerKnowledgeStatementList(destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedKnowledgeStatementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerKnowledgeStatementList(destinationUris, limit, offset, originUris, populationUris, viaUris, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
         * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [originUris] Multiple values may be separated by commas.
         * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
         * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerKnowledgeStatementList(destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options?: any): AxiosPromise<PaginatedKnowledgeStatementList> {
            return localVarFp.composerKnowledgeStatementList(destinationUris, limit, offset, originUris, populationUris, viaUris, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
     * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<string>} [originUris] Multiple values may be separated by commas.
     * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
     * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public composerKnowledgeStatementList(destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).composerKnowledgeStatementList(destinationUris, limit, offset, originUris, populationUris, viaUris, options).then((request) => request(this.axios, this.basePath));
    }
}



